<template>
    <div class="subscrib_wrap">
        <div class="top-content">
            <a-row>
                <h1>加入<span style="color:#FEC967;">火花音悦订阅会员</span></h1>
                <h2>企业商用正版音乐授权零风险，个人创作者非商业用途选用灵活</h2>
            </a-row>
             <a-row class="container" style="margin-top:55px;">
                <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6"  class="equaity-cont">
                    <div>
                        <img src="@/assets/images/web/introduce/copyright.png" />
                    </div>
                    <div>
                        <p>版权保障</p>
                        <p>正版商用，无版权风险</p>
                    </div>
                </a-col>
                 <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6"  class="equaity-cont">
                    <div>
                        <img src="@/assets/images/web/introduce/price.png" />
                    </div>
                    <div>
                        <p>超低价格</p>
                        <p>企业订阅会员，低至399元/月</p>
                    </div>
                </a-col>
                 <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6"  class="equaity-cont">
                    <div>
                        <img src="@/assets/images/web/introduce/auth.png" />
                    </div>
                    <div>
                        <p>永久授权</p>
                        <p>一次授权，永久使用</p>
                    </div>
                </a-col>
                 <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6"  class="equaity-cont">
                    <div>
                        <img src="@/assets/images/web/introduce/certificate.png" />
                    </div>
                    <div>
                        <p>授权证书</p>
                        <p>电子版授权书，实时查询</p>
                    </div>
                </a-col>
            </a-row>
        </div>
        <div class="container subscrib-content" style="padding-top:60px;margin-top:-164px;">
            <a-row>
                <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="subscrib-info">
                    <div>
                        <p>单曲购买</p>
                        <p>指定授权场景</p>
                        <p>
                            低至 ¥<span>70</span>/首
                        </p>
                        <p>
                            <router-link :to="`/allmusic`"><span>单曲购买</span></router-link>
                        </p>
                        <p>适合音乐需求量较少的企业或个人自由选择授权场景按需购买</p>
                        <div class="info-row">
                            <a-row><span>曲库范围</span><span style="color:#FF7D14;">全曲库</span></a-row>
                            <a-row><span>下载次数</span><span>灵活选择</span></a-row>
                            <a-row><span>授权项目数量</span><span>单个项目</span></a-row>
                            <a-row><span>授权期限</span><span>一年&永久</span></a-row>
                            <a-row><span>授权地域</span><span>全球</span></a-row>
                            <a-row><span>授权协议</span><span>包含</span></a-row>
                            <a-row><span>商业用途</span><span>可做商用</span></a-row>
                            <a-row><span>正规发票和商用授权书</span><span>包含</span> </a-row>
                        </div>
                    </div>
                </a-col>

                 <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="subscrib-info">
                    <div>
                        <p>个人VIP</p>
                        <p>个人非商用</p>
                        <p>
                            ¥<span>299</span>/年
                        </p>
                        <p>
                            <router-link :to="`/subscribe?vipType=2`"><span>立即开通</span></router-link>
                        </p>
                        <p>适合学生、短视频博主、个人自媒体账号、UP主等自由职业者</p>
                        <div class="info-row">
                            <a-row><span>曲库范围</span><span style="color:#FF7D14;">订阅曲库&音效库</span></a-row>
                            <a-row><span>下载次数</span><span>无限次/年</span></a-row>
                            <a-row><span>授权项目数量</span><span>无限制</span></a-row>
                            <a-row><span>授权期限</span><span>随片永久</span></a-row>
                            <a-row><span>授权地域</span><span>中国</span></a-row>
                            <a-row><span>授权协议</span><span>包含</span></a-row>
                            <a-row><span>商业用途</span><span>不可商用</span></a-row>
                            <a-row><span>正规发票和商用授权书</span><span>包含</span> </a-row>
                        </div>
                    </div>
                </a-col>

                <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="subscrib-info">
                    <div>
                        <p>企业VIP</p>
                        <p>企业商用授权</p>
                        <p>
                            ¥<span>3999</span>/年
                        </p>
                        <p>
                            <router-link :to="`/subscribe?vipType=1`"><span>立即开通</span></router-link>
                        </p>
                        <p>适合中小型企业，满足日常高频音乐使用的需求</p>
                        <div class="info-row">
                            <a-row><span>曲库范围</span><span style="color:#FF7D14;">订阅曲库&音效库</span></a-row>
                            <a-row><span>下载次数</span><span>无限次/年</span></a-row>
                            <a-row><span>授权项目数量</span><span>无限制</span></a-row>
                            <a-row><span>授权期限</span><span>随片永久</span></a-row>
                            <a-row><span>授权地域</span><span>全球</span></a-row>
                            <a-row><span>授权协议</span><span>包含</span></a-row>
                            <a-row><span>商业用途</span><span>可商用</span></a-row>
                            <a-row><span>正规发票和商用授权书</span><span>包含</span> </a-row>
                        </div>
                    </div>
                </a-col>

                <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="subscrib-info">
                    <div>
                        <p>企业定制</p>
                        <p>企业商用授权</p>
                        <p>
                            <span>定制高级音乐内容</span>
                        </p>
                        <p>
                            <span @click="Chatra">联系客服</span>
                        </p>
                        <p>适合大型企业/国企高级定制，满足不同项目需求</p>
                        <div class="info-row">
                            <a-row><span>曲库范围</span><span style="color:#FF7D14;">全曲库</span></a-row>
                            <a-row><span>下载次数</span><span>无限次/年</span></a-row>
                            <a-row><span>授权项目数量</span><span>无限制</span></a-row>
                            <a-row><span>授权期限</span><span>随片永久</span></a-row>
                            <a-row><span>授权地域</span><span>全球</span></a-row>
                            <a-row><span>授权协议</span><span>包含</span></a-row>
                            <a-row><span>商业用途</span><span>可商用</span></a-row>
                            <a-row><span>正规发票和商用授权书</span><span>包含</span> </a-row>
                        </div>
                    </div>
                </a-col>
                

            </a-row>
        </div>
        <div class="container battle">
            <h2 style="width: 100%; text-align:center;font-size: 30px;font-weight: 500;margin: 52px 0px;">商用权益对比</h2>
            <table>
                <tr style="height: 70px;">
                    <td colspan="2" style="width: 24%;">对比项</td>
                    <td>普通用户（全平台）</td>
                    <td style="color: #EDA624;">个人VIP（订阅曲库&音效库）</td>
                    <td style="color: #EDA624;">企业VIP（订阅曲库&音效库）</td>
                </tr>
                <tr style="height:100px;">
                    <td class="left-con">价<br/>格</td>
                    <td>音乐授权</td>
                    <td>
                        <span>低至 <span style="color:#EF1E1E;">¥70</span>/首</span>
                        <router-link :to="`/allmusic`"><p class="goon"><span>单曲购买</span></p></router-link>
                    </td>
                    <td>
                        <span><span style="color:#EF1E1E;">¥299</span>/年</span>
                        <router-link :to="`/subscribe?vipType=2`"><p class="goon"><span>立即开通</span></p></router-link>
                    </td>
                    <td>
                        <span><span style="color:#EF1E1E;">¥3999</span>/年</span>
                        <router-link :to="`/subscribe?vipType=1`"><p class="goon"><span>立即开通</span></p></router-link>
                    </td>
                </tr>
                <tr>
                    <td rowspan="4" class="left-con">授<br/>权<br/>保<br/>障</td>
                    <td>授权期限</td>
                    <td>
                        <span style="font-weight:400;">一年&永久  </span>
                        <a-tooltip style="cursor:pointer;" placement="bottom" title="可根据实际项目用途 选择一年或永久授权权">
                            <img src="@/assets/images/web/introduce/ques.svg" />
                        </a-tooltip>
                    </td>
                    <td>
                        <span style="font-weight:400;">随片永久</span> 
                        <a-tooltip style="cursor:pointer;" placement="bottom" title="可根据实际项目用途 选择一年或永久授权权">
                            <img src="@/assets/images/web/introduce/ques.svg" />
                        </a-tooltip>
                    </td>
                     <td>
                        <span style="font-weight:400;">随片永久</span> 
                        <a-tooltip style="cursor:pointer;" placement="bottom" title="可根据实际项目用途 选择一年或永久授权权">
                            <img src="@/assets/images/web/introduce/ques.svg" />
                        </a-tooltip>
                    </td>
                </tr>
                <tr>
                    <td>授权地域</td>
                    <td style="font-weight:400;">中国&全球</td>
                    <td style="font-weight:400;">中国</td>
                    <td style="font-weight:400;">全球</td>
                </tr>
                <tr>
                    <td>授权协议</td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
                <tr>
                    <td>授权证书</td>
                    <td style="font-weight:400;">在线生成电子版授权书</td>
                    <td style="font-weight:400;">在线生成电子版授权书</td>
                    <td style="font-weight:400;">在线生成电子版授权书</td>
                </tr>
                <tr>
                    <td rowspan="3" class="left-con">会<br/>员<br/>服<br/>务</td>
                    <td>在线发票</td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
                <tr>
                    <td>商业用途</td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/no.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
                <tr>
                    <td>授权项目数量</td>
                    <td style="font-weight:400;">
                        <a-tooltip style="cursor:pointer;" placement="bottom" title="仅限于在单个项目中使用，可根据项目实际用途选择授权范围">
                        单个项目  <img src="@/assets/images/web/introduce/ques.svg" />
                        </a-tooltip>
                    </td>
                    <td style="font-weight:400;">
                        <a-tooltip style="cursor:pointer;" placement="bottom" title="VIP会员有效期内可授权多个项目">
                            无限制  <img src="@/assets/images/web/introduce/ques.svg" />
                        </a-tooltip>
                    </td>
                    <td style="font-weight:400;">
                        <a-tooltip style="cursor:pointer;" placement="bottom" title="VIP会员有效期内可授权多个项目">
                            无限制  <img src="@/assets/images/web/introduce/ques.svg" />
                        </a-tooltip>
                    </td>
                </tr>
                <tr  style="height:90px;">
                    <td rowspan="8" class="left-con">商<br/>业<br/>用<br/>途</td>
                    <td>
                        <a-tooltip style="cursor:pointer;" placement="bottom" title="商用广告/宣传片：为特定品牌付费制作的商业视频均属于此类范畴">
                        商业广告/宣传片：<span style='color:rgba(102, 102, 102, 1);'>包括传统意义上的品牌广告，影视预告片或游戏预告片，以及为政企客户制作的宣传片等  <img src="@/assets/images/web/introduce/ques.svg" /></span>
                        </a-tooltip>
                    </td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/no.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
                <tr  style="height:90px;">
                    <td>自媒体：<span style='color:rgba(102, 102, 102, 1);'>公众号短视频、微博短视频、信息流短视频以及其他视频平台商用视频和PGC内容</span></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/no.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
                <tr style="height:90px;">
                    <td>影视作品：<span style='color:rgba(102, 102, 102, 1);'>院线电影、网络电影、电视连续剧、网剧、动画片、纪录片、综艺节目、相关的预告片、宣传片等</span></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/no.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
                <tr style="height:90px;">
                    <td>游戏/APP内置：<span style='color:rgba(102, 102, 102, 1);'>软件、APP、游戏、产品内置（如手机出厂预装铃声）</span></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/no.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
                <tr style="height:90px;">
                    <td>广播剧/有声读物：<span style='color:rgba(102, 102, 102, 1);'>适用于在各渠道的有声书、广播剧等音频作品中使用</span></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/no.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
                <tr style="height:90px;">
                    <td>教育课程：<span style='color:rgba(102, 102, 102, 1);'>学校、公开课、网络直播课、在线课程、教育平台等付费课程</span></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/no.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
                <tr style="height:90px;">
                    <td>商业直播：<span style='color:rgba(102, 102, 102, 1);'>各类电商平台、MCN机构商业性质的网络直播</span></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/no.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
                <tr style="height:90px;">
                    <td>现场活动：<span style='color:rgba(102, 102, 102, 1);'>各类线下活动、展览、年会、发布会、商业场所使用</span></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/no.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
                <tr  style="height:90px;">
                    <td rowspan="4">个<br/>人<br/>用<br/>途<br/>︵<br/>非<br/>商<br/>用<br/>︶</td>
                    <td>个人自媒体：<span style='color:rgba(102, 102, 102, 1);'>自媒体视频、UP主创作、vlog视频（不包含商业内容的非盈利性视频）</span></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/no.svg" /></td>
                </tr>
                <tr style="height:90px;">
                    <td>个人影视作品：<span style='color:rgba(102, 102, 102, 1);'>个人独立电影、独立动画、纪录片</span></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/no.svg" /></td>
                </tr>
                <tr style="height:90px;">
                    <td>教育用途：<span style='color:rgba(102, 102, 102, 1);'>在校学生毕业设计或在校园内传播仅为教育目使用的音视频内容</span></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/no.svg" /></td>
                </tr>
                <tr style="height:90px;">
                    <td>家庭视频：<span style='color:rgba(102, 102, 102, 1);'>电子相册、婚礼视频、生活视频等纪念性音视频内容</span></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/no.svg" /></td>
                </tr>
                <tr  style="height:90px;">
                    <td rowspan="4" class="left-con">网<br/>站<br/>基<br/>础<br/>服<br/>务</td>
                    <td>VIP用户尊贵标识</td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
                <tr style="height:90px;">
                    <td>智能搜索</td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
                <tr style="height:90px;">
                    <td>选曲顾问</td>
                    <td style="font-weight: 400;">在线客服</td>
                    <td style="font-weight: 400;">一对一选曲服务</td>
                    <td style="font-weight: 400;">一对一选曲服务</td>
                </tr>
                <tr style="height:90px;">
                    <td>下载音乐无水印</td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                    <td><img src="@/assets/images/web/introduce/yes.svg" /></td>
                </tr>
            </table>
        </div>
        <div class="container question">
            <a-row style="text-align:center;">
                <h1>常见问题</h1>
            </a-row>
            <a-row>
                <h3>1、我是否可以购买“个人VIP”会员，下载音频为我的公司使用？</h3>
                <a-col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;不可以。个人VIP会员仅允许个人内容创作者，将音乐用于个人频道发布的网络视频、音频播客、网络直播等用途，如果用于公司和商业用途，请购买企业VIP会员。</a-col>
            </a-row>
            <a-row>
                <h3>2、我是一家MCN，需要购买哪种会员？</h3>
                <a-col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您需要购买“企业VIP” 会员或致电13611227841联系我们，我们将根据您的实际情况为您定制一款合适的产品方案。</a-col>
            </a-row>
            <a-row>
                <h3>3、我是自由职业者，是否可以购买个人VIP会员？</h3>
                <a-col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如果您是将音乐用于非商业推广类的个人内容作品，那么可以购买使用个人VIP会员。如果您是将音乐用于第三方商业项目，如广告片、宣传片制作、影视剧配乐等，则需要购买企业VIP会员。</a-col>
            </a-row>
            <a-row>
                <h3>4、个人VIP会员和企业VIP会员到期后，是否可以继续使用已下载的音乐？</h3>
                <a-col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1、个人VIP：针对已经发布在您个人频道的视频、音频内容，可以继续保留，无需删除。但不得将下载的音乐继续用于新的视频配乐。您需要继续购买个人VIP会员后，才可以继续使用。</a-col>
                <a-col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、企业VIP：VIP会员期间下载制作完成且首次公开发表的音乐作品可在授权范围内永久授权,但不得将下载的音乐继续用于新的视频配乐。您需要继续购买企业VIP会员后，才可以继续使用。</a-col>
            </a-row>
            <a-row>
                <h3>5、网站提供的产品套餐无法满足我的需求怎么办？</h3>
                <a-col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如需定制化服务，请致电13611227841或通过在线客服联系我们，我们将根据您的实际需求为您定制适合的产品及价格方案。</a-col>
            </a-row>
        </div>
  </div>
</template>
<script>
import {tooltip as ATooltip} from 'ant-design-vue';
export default {
    data() {
      return {
      };
    },
    components: {
        ATooltip,
    },
    created(){
    },
    methods: {
        Chatra(){
            window.Chatra('openChat', true)
        },
    },
}

</script>
<style lang="scss" scoped>
.subscrib_wrap{
}
.top-content {
    width: 100%;
    background-image: url('../../assets/images/web/introduce/bg.png');
    background-size: 100%;
    text-align: center;
    height: 480px;
    color: #fff;
    padding-top: 95px;
    h1{
        font-size: 34px;
        font-weight: 600;
        color: #FFFFFF;
    }
    h2{
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
    }

    .equaity-cont{
        div:nth-child(1), div:nth-child(2){
            display: inline-block;
            vertical-align: top;
        }
        div:nth-child(2){
            width: 73%;
            text-align:left;
            margin-left:2%;
            p{
                display: block;
                color: #FFFFFF;
                margin:0;
            }
            p:nth-child(1){
                font-size: 20px;
                font-weight: 500;
                color: #FEC967;
                line-height:30px;
            }
            p:nth-child(2){
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
}
.subscrib-content{
    .subscrib-info > div{
        p:nth-child(1){
            margin: 0;
            padding-top:16px;
            font-size: 24px;
            font-weight: 600;
            color: #FEC967;
            line-height: 33px;
            text-align:center;
        }
        p:nth-child(2){
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            color: #FFE4B2;
            line-height: 22px;
            height:40px;
            text-align:center;
            padding-top:8px;
        }
        p:nth-child(3){
            margin-top:11px;
            line-height: 45px;
            font-size: 12px;
            text-align:center;
            font-weight: 400;
            color: #333333;
            span{
                font-size:32px;
                font-weight: 500;
                color:#EE1E1E;
            }
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        p:nth-child(4){
            width: 90%;
            margin: 0 auto;
            margin-top: 18px;
            line-height: 30px;
            background: linear-gradient(180deg, #FF992B 0%, #FF7B13 100%);
            border-radius: 4px;
            text-align: center;
            cursor:pointer;
            span{
                font-size: 14px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 20px;
            }
        }
        p:nth-child(5){
            width: 90%;
            margin: 0 auto;
            margin-top: 18px;
            height: 40px;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
        }
        .info-row{
            width: 90%;
            margin: 0 auto;
            background: #F8FAFC;
            border-radius: 2px;
            margin-top:18px;
            margin-bottom: 25px;
            padding: 8%;
            .ant-row{
                span{
                    display: inline-block;
                    font-size: 12px;
                    font-weight: 400;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                span:nth-child(1){
                    width: 65%;
                    color: #999999;
                    line-height: 25px;
                }
                span:nth-child(2){
                    color: #666666;
                    line-height: 25px;
                    width: 35%;
                    text-align:right;
                }
            }
        }
    }
    .subscrib-info > div{
        width: 90%;
        margin: 4% auto;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #DADADA;
    }

    .subscrib-info:nth-child(1){
        div{
            p:nth-child(1), p:nth-child(2){
                background: #8690A2;
                color: #FFFFFF;
                border-radius: 2px 2px 0px 0px;
            }
        }
    }

    .subscrib-info:nth-child(2){
        div{
            p:nth-child(1), p:nth-child(2){
                background: #5B688B;
                border-radius: 2px 2px 0px 0px;
            }
        }
    }

    .subscrib-info:nth-child(3){
        div{
            p:nth-child(1), p:nth-child(2){
                background: #717F9F;
                border-radius: 2px 2px 0px 0px;
            }
        }
    }

    .subscrib-info:nth-child(4){
        div{
            p:nth-child(1), p:nth-child(2){
                background: #5A627B;
                border-radius: 2px 2px 0px 0px;
            }
            p:nth-child(3){
                span{
                    font-size:32px;
                    font-weight: 500;
                    color:#333333;
                }
            }
        }
    }

    .subscrib-info:hover{
        cursor: pointer;
        box-shadow: 0 0 20px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.3);
        -moz-box-shadow: 0 0 20px rgba(0,0,0,0.3);
        -o-box-shadow: 0 0 20px rgba(0,0,0,0.3);
    }

}
.bottom-content{
    width: 100%;
    height:383px;
    background-image: url('../../assets/images/web/introduce/equity_bg.png');
    background-repeat:no-repeat;
    text-align: center;
    color: #fff; 
    padding-top: 60px;
    h1{
        font-size: 30px;
        font-weight: 500;
        color: #FFFFFF;
    }
}

.battle{
    margin-bottom:50px;
    table{
        text-align:center;
        font-size:14px;
        font-weight: 500;
        color: #333333;
        border-collapse: collapse;
    }
    table, td, th
    {
        border:1px solid #E6E6E6;
    }
    tr{
        height: 50px;
        .left-con{
            width: 4.31%;
            word-wrap: break-word;
        }
    }
    tr td{
        border-bottom: 0px;
    }
}
.question{
    margin-top: 50px;
    .ant-row{
        margin-bottom: 24px;
    }
}
.goon{
    width:50%;
    line-height:30px;
    margin:0 auto; background: linear-gradient(180deg, #FF992B 0%, #FF7B13 100%);
    border-radius: 15px;
    span{
        margin-top: 7px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
    }
}
</style>